import { Controller } from "@hotwired/stimulus"
import hljs from 'highlight.js';

export default class extends Controller {
  static targets = ["iframe", "codeBlock", "cardContainer", "fontSelector", "fontColorPicker", "buttonColorPicker", "backgroundColorPicker", "noBackgroundCheckbox", "backgroundSelectGroup", "toggleGroup", "copyFeedback"];
  formStyle = "";

  initialize() {
    this.styles = {
      ".form-basin": {
        "font-family": "'Helvetica Neue', Helvetica, Arial, sans-serif",
        "padding": "40px 20px 40px 20px",
        "font-size": "16px",
        "font-weight": "400",
        "background-color": "#fff",
      },
      ".form-basin form": {
        "margin": "auto",
        "max-width": "600px",
      },
      ".form-basin label": {
        "display": "block",
        "margin-bottom": "8px",
      },
      ".form-basin input, .form-basin textarea, .form-basin select": {
        "width": "100%",
        "padding": "12px",
        "margin-bottom": "16px",
        "font-family": "inherit",
        "border": "1px solid #ccc",
        "border-radius": "4px",
        "box-sizing": "border-box",
        "transition": "border-color 0.3s",
      },
      ".form-basin input:focus, .form-basin textarea:focus, .form-basin select:focus": {
        "border-color": "#007bff",
        "outline": "none",
      },
      ".form-basin button": {
        "background-color": "#007bff",
        "color": "white",
        "padding": "10px 20px",
        "border": "none",
        "border-radius": "4px",
        "cursor": "pointer",
        "font-weight": "500",
        "letter-spacing": "1px",
        "transition": "background-color 0.3s",
      },
      ".form-basin button:hover": {
        "filter": "brightness(85%)",
      },
      ".form-basin ::placeholder": {
        "font-family": "inherit",
        "opacity": 0.7,
      },
      ".form-basin input[type='checkbox']": {
        "margin-right": "8px",
        "width": "auto", // Override for checkboxes to not be full width
      },
      ".form-basin fieldset": {
        "border": "1px solid #ccc",
        "padding": "10px",
        "margin-bottom": "16px",
        "border-radius": "4px",
      },
      ".form-basin legend": {
        "padding": "0 10px",
        "font-weight": "bold",
        "font-size": "1em",
      },
      ".form-basin input[type='radio']": {
        "margin-right": "8px",
        "margin-top": "4px", // Adjusts the vertical alignment with the label
        "width": "auto", // Ensures radio buttons are not full width
        "cursor": "pointer", // Changes cursor to pointer when hovering over the radio button
      },
      ".form-basin .radio-label": {
        "display": "inline-block",
        "margin-right": "15px", // Space between radio button options
        "cursor": "pointer", // Changes cursor to pointer when hovering over the label
      },
      ".form-basin .radio-group": {
        "margin-bottom": "16px", // Space after the entire radio group
      },
      ".form-basin .donation-type": {
        "margin-bottom": "16px"
      },
      ".form-basin .donation-type span": {
        "font-weight": "500",
        "margin-right": "10px"
      },
      ".form-basin input[type='number']": {
        "-webkit-appearance": "textfield", // Overrides default appearance for number input to allow consistent styling
        "-moz-appearance": "textfield",
        "appearance": "textfield"
      },
      ".form-basin input[type='number']::-webkit-inner-spin-button, .form-basin input[type='number']::-webkit-outer-spin-button": {
        "-webkit-appearance": "none", // Removes the inner spin buttons in WebKit browsers for number inputs
        "margin": "0"
      },
      ".form-basin .form-check": {
        "display": "flex",
        "align-items": "center",
        "margin-bottom": "16px" // Adds some space below the checkbox
      },
      ".form-basin .form-check-input": {
        "margin-top": "14px",
        "margin-right": "10px",
        "cursor": "pointer"
      },
      ".form-basin .form-check-label": {
        "margin-bottom": "0",
        "cursor": "pointer"
      }
    };

    this.defaultButtonCopy = "Send";
  }


  connect() {
    this.noBackgroundCheckboxTarget.checked = true; // Default to no background
    this.toggleBackgroundSelect(); // Apply initial background select state
    this.loadFormTemplate(); // Load the form template
    this.applyInitialStyles(); // Apply initial styles
    this.showPreview(); // Show the preview by default
  }

  loadFormTemplate() {
    const templateId = this.element.dataset.template || "defaultFormTemplate";
    const templateContent = document.querySelector(`#${templateId}`).textContent;
    const iframeDoc = this.iframeTarget.contentDocument || this.iframeTarget.contentWindow.document;

    // Clear previous content
    iframeDoc.body.innerHTML = "";

    // Create a new div element
    const div = iframeDoc.createElement('div');
    div.innerHTML = templateContent;

    // Append the new content to the iframe's body
    iframeDoc.body.appendChild(div);
  }

  applyInitialStyles() {
    this.updateStyles();
  }

  updateStyles() {
    const cssString = this.stylesToJson();
    this.iframeTarget.contentDocument.head.innerHTML = `<style>${cssString}</style>`;
    this.updateCodeView();
  }

  changeFontColor() {
    const color = this.fontColorPickerTarget.value;
    this.styles[".form-basin"]["color"] = color; // Assuming you have a color property
    this.applyStyles();
  }

  stylesToJson() {
    return Object.entries(this.styles).reduce((cssString, [selector, styles]) => {
      const stylesString = Object.entries(styles).map(([property, value]) => `${property}: ${value};`).join(" ");
      return `${cssString} ${selector} { ${stylesString} }`;
    }, "");
  }

  changeButtonColor() {
    const color = this.buttonColorPickerTarget.value;
    // Assuming your button styles are directly under .form-basin button selector
    this.styles[".form-basin button"]["background-color"] = color;
    this.applyStyles();
  }

  changeBackgroundColor(arg) {
    // Determine if the argument is an event or a color value
    const color = arg instanceof Event ? arg.target.value : arg;

    // Update the styles object and apply styles
    this.styles[".form-basin"]["background-color"] = color || 'transparent';
    this.applyStyles(); // Re-apply all styles to reflect the change

    // Match the card background to the iframe background for a consistent look
    this.cardContainerTarget.style.backgroundColor = color; // Update the background color of the card container
  }

  changeFontSize(event) {
    const size = event.target.value;
    this.styles[".form-basin"]["font-size"] = size + "px";
    this.styles[".form-basin button"]["font-size"] = size + "px";
    this.applyStyles();
  }

  changeFontWeight(event) {
    const weight = event.target.value;
    this.styles[".form-basin"]["font-weight"] = weight;
    this.styles[".form-basin button"]["font-weight"] = weight;
    this.applyStyles();
  }

  changeButtonCopy(event) {
    const copy = event.target.value;
    // Directly modify the button text in the iframe
    const button = this.iframeTarget.contentDocument.querySelector(".form-basin button");
    if (button) button.textContent = copy;
    this.updateCodeView(); // Update the code view to reflect changes
  }

  applyStyles() {
    const cssString = this.stylesToJson();
    this.iframeTarget.contentDocument.head.innerHTML = `<style>${cssString}</style>`;
    this.updateCodeView();
  }

  showPreview(event) {
    if (event) event.preventDefault();
    this.iframeTarget.classList.remove('d-none');
    this.codeBlockTarget.classList.add('d-none');
    this.toggleButtonStyles(true);
  }

  changeFont() {
    const font = this.fontSelectorTarget.value; // Get the selected font value
    this.styles[".form-basin"]["font-family"] = `${font}`; // Update your styles object
    this.styles[".form-basin button"]["font-family"] = `${font}`; // Update your styles object
    this.applyStyles(); // Apply the updated styles
  }

  showCode(event) {
    if (event) event.preventDefault(); // Only call preventDefault if an event exists

    const iframeDocument = this.iframeTarget.contentDocument || this.iframeTarget.contentWindow.document;

    const styleElements = iframeDocument.querySelectorAll("style");
    const formElement = iframeDocument.querySelector(".form-basin");

    let cssString = Array.from(styleElements).map(el => el.outerHTML).join("");
    let formHTML = formElement.outerHTML;
    const combinedHTML = `${cssString}\n\n${formHTML}`;

    this.codeBlockTarget.textContent = combinedHTML;
    this.codeBlockTarget.classList.remove('d-none');
    this.iframeTarget.classList.add('d-none');

    this.toggleButtonStyles(false);
    hljs.highlightElement(this.codeBlockTarget);
  }

  updateCodeView() {
    const iframeDocument = this.iframeTarget.contentDocument || this.iframeTarget.contentWindow.document;
    const formHTML = iframeDocument.body.innerHTML; // Get the form HTML
    const cssString = this.stylesToJson(); // Convert current styles to CSS string
    this.codeBlockTarget.textContent = `<style>${cssString}</style>\n\n\n${formHTML}`; // Combine CSS and HTML
    hljs.highlightElement(this.codeBlockTarget);
  }

  toggleButtonStyles(isPreview) {
    const previewButton = this.toggleGroupTarget.querySelector('[data-action="click->form-generator#showPreview"]');
    const codeButton = this.toggleGroupTarget.querySelector('[data-action="click->form-generator#showCode"]');

    previewButton.classList.toggle('btn-primary', isPreview);
    previewButton.classList.toggle('btn-outline-primary', !isPreview);

    codeButton.classList.toggle('btn-primary', !isPreview);
    codeButton.classList.toggle('btn-outline-primary', isPreview);
  }

  toggleBackgroundSelect() {
    const isChecked = this.noBackgroundCheckboxTarget.checked;

    // Toggle visibility of the background color picker
    this.backgroundSelectGroupTarget.classList.toggle('d-none', isChecked);

    // Apply or remove the background color based on the checkbox state
    if (!isChecked) {
      // Apply the selected background color
      this.changeBackgroundColor(this.backgroundColorPickerTarget.value);
    } else {
      // Remove any custom background color when checkbox is checked
      this.changeBackgroundColor('transparent');
    }
  }

  copyToClipboard(event) {
    event.preventDefault();

    // Assuming `this.codeBlockTarget` contains the code you want to copy
    if (this.codeBlockTarget) {
      const textarea = document.createElement('textarea');
      textarea.value = this.codeBlockTarget.textContent;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);

      // Show the "Copied!" feedback message
      this.showCopyFeedback();
    }
  }

  showCopyFeedback() {
    const feedbackElement = this.copyFeedbackTarget;
    feedbackElement.classList.remove('d-none');

    // Hide the feedback message after 2 seconds
    setTimeout(() => feedbackElement.classList.add('d-none'), 2000);
  }


}
