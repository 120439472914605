import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["mfaStep", "email", "password", "form"]

  connect() {
    console.log("Login Controller Connected...");
  }

  submitForm(event) {
    event.preventDefault();
    this.emailTarget.disabled = false;
    this.passwordTarget.disabled = false;
    console.log("Controller working");


    if (this.mfaStepTarget.style.display === 'none') {
      this.checkMFA()
    } else {
      this.formTarget.submit();
    }
  }

  checkMFA() {
    fetch('/check_mfa', {
      method: 'POST',
      body: new FormData(this.formTarget),
    })
    .then(response => response.json())
    .then(data => {
      if (data.requires_mfa) {
        this.showMfaStep()
      } else {
        this.formTarget.submit();
      }
    })
    .catch(error => {
      console.error('Error:', error)
    })
  }

  showMfaStep() {
    this.mfaStepTargets.forEach(el => el.style.display = 'flex')
    this.emailTarget.disabled = true;
    this.passwordTarget.disabled = true;
  }

  resendEmail(event) {
    event.preventDefault();
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    this.emailTarget.disabled = false;
    fetch('/resend_email', {
      method: "POST",
      body: new FormData(this.formTarget),
    })
    .catch(error => {
      console.error('Error:', error)
    })
    this.emailTarget.disabled = true;

    const link = event.target;
    link.removeEventListener("click", this.resendEmail);
    link.innerHTML = "Sent!";

  }
}
