import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "popover"
  ]

  connect() {
    console.log("Popover controller connected!");

    this.popoverTargets.forEach((element) => {
      console.log("Initializing popover target");
      $(element).popover({
        container: 'body',
        html: true
      });
    });
  }
}