import { Application } from "@hotwired/stimulus";
const application = Application.start();
import PricingController from './controllers/pricing_controller.js';
import SpamPopoverController from './controllers/spam_popover_controller.js';
import LoginController from './controllers/login_controller.js';
import FormGeneratorController from './controllers/form_generator_controller.js';
application.register('pricing', PricingController);
application.register('spam-popover', SpamPopoverController);
application.register('login', LoginController);
application.register('form-generator', FormGeneratorController);

import hljs from 'highlight.js';
hljs.configure({
  languages: ['html'],
  cssSelector: '.hljs',
  ignoreUnescapedHTML: true,
});

document.addEventListener('DOMContentLoaded', (event) => {
  document.querySelectorAll('pre').forEach((block) => {
    hljs.highlightElement(block);
  });
});
